
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    nativeType: {
      type: String,
      default: 'button',
    },
    size: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
