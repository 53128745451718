
// import Cookie from '~/assets/img/cookie.svg?inline';

export default {
  // components: {
  //   Cookie,
  // },

  data() {
    return {
      isReady: false,
      isAccepted: false,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (window.localStorage.getItem('cookies-accepted') || this.$route.query.acceptCookie) {
        this.accept();
      }
      this.isReady = true;
    },
    accept() {
      this.isAccepted = true;
      // this.$gtm.init('GTM-WNDJ3K9');
      window.localStorage.setItem('cookies-accepted', true);
    },
  },
};
