export const AboutAdvisory = () => import('../../components/about/AboutAdvisory.vue' /* webpackChunkName: "components/about-advisory" */).then(c => wrapFunctional(c.default || c))
export const AboutHero = () => import('../../components/about/AboutHero.vue' /* webpackChunkName: "components/about-hero" */).then(c => wrapFunctional(c.default || c))
export const AboutPartners = () => import('../../components/about/AboutPartners.vue' /* webpackChunkName: "components/about-partners" */).then(c => wrapFunctional(c.default || c))
export const AboutTeam = () => import('../../components/about/AboutTeam.vue' /* webpackChunkName: "components/about-team" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/home/HomeHero.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const HomePartnership = () => import('../../components/home/HomePartnership.vue' /* webpackChunkName: "components/home-partnership" */).then(c => wrapFunctional(c.default || c))
export const HomePatients = () => import('../../components/home/HomePatients.vue' /* webpackChunkName: "components/home-patients" */).then(c => wrapFunctional(c.default || c))
export const HomePatientsCounter = () => import('../../components/home/HomePatientsCounter.vue' /* webpackChunkName: "components/home-patients-counter" */).then(c => wrapFunctional(c.default || c))
export const HomePatientsCounterDigit = () => import('../../components/home/HomePatientsCounterDigit.vue' /* webpackChunkName: "components/home-patients-counter-digit" */).then(c => wrapFunctional(c.default || c))
export const HomeProblems = () => import('../../components/home/HomeProblems.vue' /* webpackChunkName: "components/home-problems" */).then(c => wrapFunctional(c.default || c))
export const HomeProducts = () => import('../../components/home/HomeProducts.vue' /* webpackChunkName: "components/home-products" */).then(c => wrapFunctional(c.default || c))
export const ProductsHero = () => import('../../components/products/ProductsHero.vue' /* webpackChunkName: "components/products-hero" */).then(c => wrapFunctional(c.default || c))
export const ProductsProduct = () => import('../../components/products/ProductsProduct.vue' /* webpackChunkName: "components/products-product" */).then(c => wrapFunctional(c.default || c))
export const BurgerDialog = () => import('../../components/ui/BurgerDialog.vue' /* webpackChunkName: "components/burger-dialog" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/ui/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const CookieBar = () => import('../../components/ui/CookieBar.vue' /* webpackChunkName: "components/cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/ui/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/ui/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
