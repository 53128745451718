import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c9a8876a = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _22c38d40 = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _84f04296 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _13110dd1 = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _57b4e14a = () => interopDefault(import('../pages/ui-kit.vue' /* webpackChunkName: "pages/ui-kit" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _c9a8876a,
    name: "about-us___cs"
  }, {
    path: "/contacts",
    component: _22c38d40,
    name: "contacts___cs"
  }, {
    path: "/en",
    component: _84f04296,
    name: "index___en"
  }, {
    path: "/products",
    component: _13110dd1,
    name: "products___cs"
  }, {
    path: "/ui-kit",
    component: _57b4e14a,
    name: "ui-kit___cs"
  }, {
    path: "/en/about-us",
    component: _c9a8876a,
    name: "about-us___en"
  }, {
    path: "/en/contacts",
    component: _22c38d40,
    name: "contacts___en"
  }, {
    path: "/en/products",
    component: _13110dd1,
    name: "products___en"
  }, {
    path: "/en/ui-kit",
    component: _57b4e14a,
    name: "ui-kit___en"
  }, {
    path: "/",
    component: _84f04296,
    name: "index___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
