export const state = () => ({
  isDarkMode: false,
  isBurgerMenuOpen: false,
  navItems: [
    {
      hash: 'about-us',
      to: '/about-us',
    },
    {
      hash: 'products',
      to: '/products',
    },
    {
      hash: 'contacts',
      to: '/contacts',
    },
  ],
});

export const mutations = {
  setIsDarkMode(state, payload) {
    state.isDarkMode = payload;
  },
  setIsBurgerMenuOpen(state, payload) {
    state.isBurgerMenuOpen = payload;
  },
};

export const getters = {

};

export const actions = {

};
