import Vue from 'vue';

import LiveEdit from './LiveEdit.vue';
import LiveLogin from './LiveLogin.vue';
import LiveBar from './LiveBar.vue';
import LiveImage from './LiveImage.vue';
import LiveText from './LiveText.vue';
import LiveLink from './LiveLink.vue';
import LiveList from './LiveList.vue';
import LiveListItem from './LiveListItem.vue';
import prepositionSpace from './preposition-space.js';
import liveEditStore from './live-edit.store';

export default ({ app }, inject) => {
  const getLocale = (i18n) => i18n ? i18n.locale : 'en';

  if (!app.store) {
    throw new Error('Create store/index.js file.');
  }
  if (!'https://neurona-258tc9qad-channel-plus.vercel.app/api') { // eslint-disable-line no-constant-condition
    throw new Error('Set options api');
  }

  app.store.registerModule('liveEdit', liveEditStore);
  app.store.commit('liveEdit/setBaseUrl', 'https://neurona-258tc9qad-channel-plus.vercel.app/api');
  app.store.commit('liveEdit/setAssetsUrl', 'https://s3.eu-central-1.amazonaws.com/neurona/neurona');
  app.store.commit('liveEdit/setImagekitUrl', 'https://ik.imagekit.io/channelplus');

  if (app.i18n) {
    app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
      app.store.dispatch('liveEdit/loadTexts', { lang: newLocale });
    };
  }

  inject('liveEdit', {
    isEditMode: () => app.store.getters['liveEdit/isEditMode'],
    loadTexts: () => app.store.dispatch('liveEdit/loadTexts', { lang: getLocale(app.i18n) }),
    getText: (hash) => app.store.getters['liveEdit/getText'](hash, getLocale(app.i18n)),
    getList: (hash) => app.store.getters['liveEdit/getList'](hash, getLocale(app.i18n)),
    addToList: (hash, index) => app.store.dispatch('liveEdit/addToList', {
      hash,
      lang: getLocale(app.i18n),
      index,
    }),
    removeFromList: (hash, index) => app.store.dispatch('liveEdit/removeFromList', {
      hash,
      lang: getLocale(app.i18n),
      index,
    }),
    updateList: (hash, content) => app.store.dispatch('liveEdit/updateOrCreate', {
      hash,
      lang: getLocale(app.i18n),
      content,
    }),
    openDialog: ({ hash, text, richText }) => {
      if (app.store.getters['liveEdit/isEditMode']) {
        app.store.commit('liveEdit/updateDialog', {
          isOpen: true,
          hash,
          text,
          richText,
        });
      }
    },
    getLocale,
    prepositionSpace,
  });

  Vue.component('LiveEdit', LiveEdit);
  Vue.component('LiveBar', LiveBar);
  Vue.component('LiveLogin', LiveLogin);
  Vue.component('LiveImage', LiveImage);
  Vue.component('LiveText', LiveText);
  Vue.component('LiveLink', LiveLink);
  Vue.component('LiveList', LiveList);
  Vue.component('LiveListItem', LiveListItem);
};
