const apiMethods = ["init","vueRouterInstrumentation","attachErrorHandler","createTracingMixins","FunctionToString","Hub","InboundFilters","Scope","addBreadcrumb","addGlobalEventProcessor","captureEvent","captureException","captureMessage","configureScope","createTransport","getCurrentHub","getHubFromCarrier","makeMain","setContext","setExtra","setExtras","setTag","setTags","setUser","startTransaction","withScope","BrowserClient","makeFetchTransport","makeXHRTransport","defaultStackParser","eventFromException","eventFromMessage","close","flush","forceLoad","lastEventId","onLoad","showReportDialog","wrap","Replay","makeBrowserOfflineTransport","GlobalHandlers","TryCatch","Breadcrumbs","LinkedErrors","HttpContext","Dedupe"]

/** @type {import('@nuxt/types').Plugin} */
export default function (ctx, inject) {
  const SentryMock = {}
  apiMethods.forEach(key => {
    SentryMock[key] = (...args) => console.warn(`$sentry.${key}() called, but Sentry plugin is disabled. Arguments:`, args)
  })

  // Inject mocked sentry to the context as $sentry (this is used in case sentry is disabled)
  inject('sentry', SentryMock)
  ctx.$sentry = SentryMock
}
